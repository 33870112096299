import {
  Backdrop,
  Button,
  Card,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../Common/config";
import moment from "moment";
import CustomSpinner from "../../Common/CustomSpinner";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    borderRadius: "20px", // This changes the border radius of the dialog paper
  },
}));

function AllEnquiry(props) {
  const { TabValue } = props;
  var USERID = localStorage.getItem("USERID");
  var EmpID = localStorage.getItem("EMPID");
  const history = useNavigate();
  const [loadingCommon, setLoadingCommon] = useState(true);
  const [afterLoadingCommon, setAfterLoadingCommon] = useState(false);
  const [enquiryCardListDet, setEnquiryCardListDet] = useState([]);
  const [alreadyPickedDialog, setAlreadyPickedDialog] = useState(false);
  const [leadNamesDet, setLeadNamesDet] = useState([]);
  const [brandDet, setBrandDet] = useState([]);
  const [leadName, setLeadName] = useState(EmpID);
  const [brandId, setBrandId] = useState(0);
  const [hotLead, setHotLead] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const EnquiryCardListLoad = useCallback((EmpIDParam, BrandIdparam, HotLeadParam) => {
    axios
      .post(config.apiLink + "Alucast/EnquiryCardListLoad", {
        EmpID: EmpIDParam,
        BrandId: BrandIdparam,
        HotLead: HotLeadParam
      })
      .then(
        (response) => {
          var Temp = response.data;
          setEnquiryCardListDet(Temp["AllEnquiryCardList"]);
          setLeadNamesDet(Temp["EmployeeDet"]);
          setBrandDet(Temp["BrandDet"])
          setLoadingCommon(false);
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);

  useEffect(() => {
    var LeadNameParam = localStorage.getItem("LeadName");
    var BrandParam = localStorage.getItem("Brand");
    var HotLeadParam = localStorage.getItem("HotLead");
    setLeadName(LeadNameParam ? LeadNameParam : EmpID);
    setBrandId(BrandParam ? BrandParam : 0);
    setHotLead(HotLeadParam ? HotLeadParam : 0);
    EnquiryCardListLoad(LeadNameParam ? LeadNameParam : EmpID, BrandParam ? BrandParam : 0, HotLeadParam ? HotLeadParam : 0);
  }, [EnquiryCardListLoad, history, EmpID]);

  const AfterEnquiryCardListLoad = (EmpIDParam, BrandIdparam, HotLeadParam) => {
    var param = {
      EmpID: EmpIDParam,
      BrandId: BrandIdparam,
      HotLead: HotLeadParam
    };
    axios
      .post(config.apiLink + "Alucast/EnquiryCardListLoad", param)
      .then(
        (response) => {
          var Temp = response.data;
          setEnquiryCardListDet(Temp["AllEnquiryCardList"]);
          setLeadName(EmpIDParam);
          setAfterLoadingCommon(false);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const EnquiryCardProcessedByUpdate = (ID) => {
    axios
      .post(config.apiLink + "Alucast/EnquiryCardProcessedByUpdate", {
        EnquiryCardID: ID,
        EmpID: EmpID,
      })
      .then(
        (response) => {
          var Temp = response.data;
          if (Temp === "S") {
            AfterEnquiryCardListLoad(leadName, brandId, hotLead);
          } else if (Temp === "F") {
            setAlreadyPickedDialog(true);
            setAfterLoadingCommon(false);
          }
        },
        (error) => {
          console.log(error);
          setAfterLoadingCommon(false);
        }
      );
  };

  const handlePickIt = (ID) => {
    setAfterLoadingCommon(true);
    EnquiryCardProcessedByUpdate(ID);
  };
  const handleClearSearch = () => {
    localStorage.removeItem("LeadName");
    localStorage.removeItem("Brand");
    localStorage.removeItem("HotLead");
    window.location.href = "/EnquiryFromCardList";
  };

  const handleEnquiryFromCardDetails = (data) => {
    localStorage.setItem("LeadName", leadName);
    localStorage.setItem("Brand", brandId);
    localStorage.setItem("HotLead", hotLead);
    history("/EnquiryFromCardDetailsRePrint", {
      state: {
        params: data,
        TabValue: TabValue,
      },
    });
  };

  const handleAlreadyPickedDialogClose = () => {
    setAlreadyPickedDialog(false);
  };

  const handleSortBy = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSortByClose = () => {
    setAnchorEl(null);
  };

  const handleSortOptionClick = (sortOrder) => {
    // Add your sorting logic here
    if (sortOrder === "AscendingNames") {
      enquiryCardListDet.sort((a, b) =>
        a.MEMBERNAMES.localeCompare(b.MEMBERNAMES)
      );
    } else if (sortOrder === "DescendingNames") {
      enquiryCardListDet.sort((a, b) =>
        b.MEMBERNAMES.localeCompare(a.MEMBERNAMES)
      );
    } else if (sortOrder === "AscendingCompanyNames") {
      enquiryCardListDet.sort((a, b) =>
        a.MEMBERCOMPANYNAMES.localeCompare(b.MEMBERCOMPANYNAMES)
      );
    } else if (sortOrder === "DescendingCompanyNames") {
      enquiryCardListDet.sort((a, b) =>
        b.MEMBERCOMPANYNAMES.localeCompare(a.MEMBERCOMPANYNAMES)
      );
    }
    handleSortByClose(); // Close the popover after selection
  };

  return (
    <div className="margin_top20">
      {!loadingCommon && (USERID === "9" || USERID === "11") && (
        <Grid container justifyContent={"flex-start"} sx={{ mt: 1, mb: 2 }}>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <div className="flexStartCenterHeight">
              <FormControl fullWidth>
                <InputLabel>Lead Name</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Lead Name"
                  value={leadName}
                  onChange={(e) => {
                    setLeadName(e.target.value);
                    AfterEnquiryCardListLoad(e.target.value, brandId, hotLead);
                  }}
                >
                  <MenuItem key={0} value={0} disabled>
                    <Typography sx={{ color: "gray" }}>
                      Select any lead name to filter
                    </Typography>
                  </MenuItem>
                  <MenuItem key="all" value={0}>
                    All
                  </MenuItem>
                  {leadNamesDet.map((lang) => (
                    <MenuItem key={lang.EMPID} value={lang.EMPID}>
                      {lang.EMPLOYEENAME}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <div className="flexStartCenterHeight margin_left10">
              <FormControl fullWidth>
                <InputLabel>Brand</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Brand"
                  value={brandId}
                  onChange={(e) => {
                    setBrandId(e.target.value);
                    AfterEnquiryCardListLoad(leadName, e.target.value, hotLead);
                  }}
                >
                  <MenuItem key={0} value={0} disabled>
                    <Typography sx={{ color: "gray" }}>
                      Select any brand name to filter
                    </Typography>
                  </MenuItem>
                  {brandDet.map((brand) => (
                    <MenuItem key={brand.BRANDID} value={brand.BRANDID}>
                      {brand.BRANDNAME}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <div className="flexStartCenterHeight margin_left10">
              <FormControl fullWidth>
                <InputLabel>Hot Lead</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Hot Lead"
                  value={hotLead}
                  onChange={(e) => {
                    setHotLead(e.target.value);
                    AfterEnquiryCardListLoad(leadName, brandId, e.target.value);
                  }}
                >
                  <MenuItem key={0} value={0} disabled>
                    <Typography sx={{ color: "gray" }}>
                      Select hot lead to filter
                    </Typography>
                  </MenuItem>
                  <MenuItem value={1}>
                    Hot Lead
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={2} sm={2} md={1} lg={1}>
            <div className="flexStartCenterHeight margin_left10">
              <Button
                variant="contained"
                size="small"
                sx={{
                  pl: 5,
                  pr: 5,
                  textTransform: "capitalize",
                  bgcolor: "#fab005",
                  border: "1px solid #df9d05",
                  "&:hover": {
                    bgcolor: "#e6a504", // Slightly darker shade
                    opacity: 0.8, // Decrease opacity
                  },
                }}
                onClick={() => handleClearSearch()}
              >
                Clear
              </Button>
            </div>

          </Grid>
          <Grid item xs={2} sm={2} md={1} lg={1}>
            <div className="flexCenterCenterHeight">
              <Tooltip title="Sort By">
                <IconButton onClick={handleSortBy}>
                  <FormatListBulletedIcon
                    fontSize="small"
                  />
                </IconButton>
              </Tooltip>
            </div>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleSortByClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              PaperProps={{
                sx: {
                  borderRadius: "15px",
                  width: "260px",
                },
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ bgcolor: "#d9e9f5", p: 2, cursor: "pointer" }}
                >
                  <Typography
                    className="RobotoRegular font_size12 color-233a66"
                    onClick={() => handleSortOptionClick("AscendingNames")}
                  >
                    Contact Name - Ascending
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ bgcolor: "#f0f8ff", p: 2, cursor: "pointer" }}
                >
                  <Typography
                    className="RobotoRegular font_size12 color-233a66"
                    onClick={() => handleSortOptionClick("DescendingNames")}
                  >
                    Contact Name - Descending
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ bgcolor: "#d9e9f5", p: 2, cursor: "pointer" }}
                >
                  <Typography
                    className="RobotoRegular font_size12 color-233a66"
                    onClick={() =>
                      handleSortOptionClick("AscendingCompanyNames")
                    }
                  >
                    Company Name - Ascending
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ bgcolor: "#f0f8ff", p: 2, cursor: "pointer" }}
                >
                  <Typography
                    className="RobotoRegular font_size12 color-233a66"
                    onClick={() =>
                      handleSortOptionClick("DescendingCompanyNames")
                    }
                  >
                    Company Name - Descending
                  </Typography>
                </Grid>
              </Grid>
            </Popover>
          </Grid>
        </Grid>
      )}
      {!loadingCommon &&
        enquiryCardListDet.length !== 0 &&
        enquiryCardListDet.map((elem, ind) => (
          <Card
            key={ind}
            // data-aos="fade-up"
            sx={{
              padding: "32px 24px 24px",
              borderRadius: "10px",
              border: "1px solid #d4e6e6",
              boxShadow: 0,
              mt: 1,
              mb: 1,
            }}
          >
            <Grid container>
              <Grid item xs={2} sm={2} md={1} lg={1}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Sl No
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {ind + 1}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Lot No
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {elem.LOTNO}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={2} sm={2} md={3} lg={3}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Lead By
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {elem.LEADBYNAME}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Cards / Validated / Printed / CustomerMapped / Quoted
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {elem.TOTALCARDS}&nbsp;/&nbsp;{elem.VALIDATEDCARDS}
                    &nbsp;/&nbsp;{elem.PRINTEDCARDS}
                    &nbsp;/&nbsp;{elem.CUSTOMERMAPPED}
                    &nbsp;/&nbsp;{elem.QUOTED}
                  </Typography>
                </div>
              </Grid>
              {/* <Grid item xs={2} sm={2} md={3} lg={3} sx={{ mt: 1 }}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Taken By
                  </Typography>
                  {elem.TAKENBY !== null && (
                    <Typography
                      align="left"
                      className="InterMedium font_size18 color-161616"
                    >
                      {elem.TAKENBY}
                    </Typography>
                  )}
                  {elem.TAKENBY === null && (
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        pl: 2,
                        pr: 2,
                        textTransform: "capitalize",
                        bgcolor: "#fab005",
                        border: "1px solid #df9d05",
                        animation: "blink 1s infinite", // Add animation to the button
                        "&:hover": {
                          bgcolor: "#e6a504", // Slightly darker shade
                          opacity: 0.8, // Decrease opacity
                          animation: "none", // Disable blink on hover for better UX
                        },
                        "@keyframes blink": {
                          "0%": { opacity: 1 },
                          "50%": { opacity: 0.5 },
                          "100%": { opacity: 1 },
                        },
                      }}
                      onClick={() => handlePickIt(elem.ENQUIRYID)}
                    >
                      Pick It
                    </Button>
                  )}
                </div>
              </Grid> */}
              <Grid item xs={2} sm={2} md={3} lg={3} sx={{ mt: 1 }}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Enquiry Time
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {moment(elem.CREATEDTIME).format("DD MMM hh:mm a")}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} sx={{ mt: 1 }}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Contact Names
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {elem.MEMBERNAMES}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} sx={{ mt: 1 }}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Company
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {elem.MEMBERCOMPANYNAMES}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2} sx={{ mt: 1 }}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Product Count
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {elem.PRODUCTCOUNT}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2} sx={{ mt: 1 }}>
                <div className="flexStartCenterHeight">
                  {/* <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Hot Lead
                  </Typography> */}
                  <div
                    className={`${
                      elem.LATESTHOTLEADBY === 1 ? "glow-effect" : ""
                    }`}
                  >
                    <Typography
                      align="left"
                      className={`InterMedium font_size18 ${
                        elem.LATESTHOTLEADBY === 1 ? "blink-effect" : ""
                      }`}
                    >
                      {elem.LATESTHOTLEADBY === 1 ? "Hot Lead" : ""}
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2} sx={{ mt: 1 }}>
                <div className="flexStartCenterHeight">
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      pl: 5,
                      pr: 5,
                      textTransform: "capitalize",
                      bgcolor: "#fab005",
                      border: "1px solid #df9d05",
                      "&:hover": {
                        bgcolor: "#e6a504", // Slightly darker shade
                        opacity: 0.8, // Decrease opacity
                      },
                    }}
                    onClick={() => handleEnquiryFromCardDetails(elem)}
                  >
                    Details
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} sx={{ mt: 1 }}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Latest Comment
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {elem.LATESTCOMMENT ? elem.LATESTCOMMENT : "N/A"}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} sx={{ mt: 1 }}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Latest Comment By / Time
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {elem.LATESTCOMMENTCREATEDBY ? elem.LATESTCOMMENTCREATEDBY : "N/A"}&nbsp;/&nbsp;{elem.LATESTCOMMENTCREATEDTIME ? moment(elem.LATESTCOMMENTCREATEDTIME).format("DD MMM YYYY hh:mm a") : "N/A"}

                  </Typography>
                </div>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={3} lg={3} sx={{ mt: 1 }}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Latest Comment Created By
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {elem.LATESTCOMMENTCREATEDBY ? elem.LATESTCOMMENTCREATEDBY : "N/A"}
                  </Typography>
                </div>
              </Grid> */}
            </Grid>
          </Card>
        ))}
      <BootstrapDialog
        onClose={handleAlreadyPickedDialogClose}
        aria-labelledby="customized-dialog-title"
        open={alreadyPickedDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogContent style={{ padding: "0px" }}>
          <Card
            sx={{
              border: "2px solid #bee3ff",
              bgcolor: "#f0f8ff",
              padding: "32px 16px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  align="center"
                  className="InterMedium font_size18 color-161616"
                >
                  This enquiry has been picked already.<br></br> Try another
                  unpicked enquiry.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
                <div className="flexCenterCenterHeight">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleAlreadyPickedDialogClose}
                    sx={{
                      pl: 5,
                      pr: 5,
                      textTransform: "capitalize",
                      bgcolor: "#fab005",
                      border: "1px solid #df9d05",
                      "&:hover": {
                        bgcolor: "#e6a504", // Slightly darker shade
                        opacity: 0.8, // Decrease opacity
                      },
                    }}
                  >
                    Ok
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Card>
        </DialogContent>
      </BootstrapDialog>
      <Backdrop className="useStylesBackDrop" open={loadingCommon}>
        <CustomSpinner />
      </Backdrop>
      <Backdrop className="useStylesBackDrop" open={afterLoadingCommon}>
        <CustomSpinner />
      </Backdrop>
    </div>
  );
}

export default AllEnquiry;
